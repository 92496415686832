<template>
  <div v-if="show">
    <div class="item_line">
      <span
        class="titlename"
        style="font-size: 18px; color: #333; font-weight: bold"
        >{{ form.name }}</span
      >
    </div>
    <div class="item_line">
      <span class="titlename">会议议程</span>
    </div>
    <div
      class="item_line"
      style="margin-left: 10px; width: 70%; justify-content: flex-end"
      v-show="!istz && roleid == 2"
    >
      <el-button type="primary" size="mini" @click="showselect = !showselect">{{
        showselect ? "取消编辑" : "编辑"
      }}</el-button>
    </div>
    <div style="width: 70%; margin-left: 10px">
      <textarea
        v-show="!showselect"
        style="width: 100%; border: none; height: 150px; font-size: 14px"
      >
				{{ textarea }}
			</textarea
      >
      <el-input
        v-show="showselect"
        type="textarea"
        :rows="6"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
    </div>
    <div
      class="item_line"
      style="
        margin-left: 10px;
        width: 70%;
        justify-content: center;
        margin-top: 20px;
      "
    >
      <el-button type="primary" v-show="showselect" @click="save"
        >确认保存</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    istz: {
      type: Boolean,
      default: false,
    },
    updataQx: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      show: false,
      form: "",
      showselect: false,
      textarea: "",
      roleid: "",
    };
  },
  created() {
    this.roleid = this.updataQx;
  },
  methods: {
    save() {
      this.$post({
        url: "/api/invest_research_wymeeting/edit2",
        params: {
          hyyc: this.textarea,
          id: this.form.id,
        },
      }).then((res) => {
        this.showselect = false;
        this.$message.success("操作成功");
        this.$emit("getinfo");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tapmenu {
  font-size: 16px;
  margin-right: 16px;
  cursor: pointer;
  padding-bottom: 5px;
}
.active_tap {
  color: #ec4a4a;
  border-bottom: 2px solid #ec4a4a;
}
.item_line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .titlename {
    margin-left: 10px;
    font-size: 14px;

    text-align: right;
    color: #333;
  }
  .bticon {
    width: 10px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 34px;
    font-size: 26px;
    color: red;
  }
}
.infobox {
  width: 100%;
  padding-left: 50px;
  padding-right: 30px;
  .contbox {
    display: flex;
    padding: 20px 40px;
    height: 750px;
    transition: all 0.5s;
    overflow: hidden;
  }
  .colosecont {
    height: 0;
    padding: 0;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    color: #ec4a4a;
    border-bottom: 1px solid #ec4a4a;
  }
}
.addbox {
  height: 85vh;
  width: 100%;
  overflow-y: auto;
  padding: 30px;
  background-color: #fff;
}

.add_cont {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.btnbox {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-form-item__label {
  color: #999;
  font-size: 14px;
}

::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "" !important;
}

::v-deep .el-input--small .el-input__icon {
  color: #333;
  font-weight: bold;
}

::v-deep .el-dialog {
  margin: 0 !important;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
  padding: 10px;
  padding-top: 0px;
}

::v-deep .el-tabs--border-card {
  height: 100%;
}

::v-deep .el-tabs--border-card {
  box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
  background-color: #fff;
}
::v-deep .myinputicon {
  cursor: pointer;
}
::v-deep .myinputicon:hover {
  color: red !important;
}
</style>